<!--  -->
<template>
  <div class="page-loadmore-wrapper">
    <mt-loadmore 
    :top-method="loadTop" 
    :bottom-method="loadBottom" 
    :bottom-all-loaded="bottomAllLoaded" 
    :auto-fill="false" ref="loadmore"
    @bottom-status-change="handleBottomChange"
    bottomPullText=""
    bottomLoadingText="加载更多...">
      <ul class="task-list">
        <task-pannel v-for="item in this.taskList" :key="item.id" :task="item">
        </task-pannel>
      </ul>
    </mt-loadmore>
    <p v-if="listState === 0" class="load-more">暂无数据</p>
    <p v-else-if="listState === 1" class="load-more">没有更多了</p>
    <p v-else-if="listState === 2" class="load-more">下拉加载更多</p>
  </div>
</template>

<script>
  import {
    getTaskList
  } from "@api/tasks";
  import {
    formatDate
  } from "@utils/common";
  import taskPannel from "@components/task/task-pannel";
  export default {
    components: {
      taskPannel
    },
    props: ["state", "pageSize"],
    data() {
      return {
        searchKey: {
          pageNum: 1,
          pageSize: this.pageSize - 0,
          state: this.state - 0,
          source: 1
        },
        bottomStatus: 'loading',
        bottomAllLoaded: false,
        taskList: [],
        listState: -1 // 0 暂无数据， 1 没有更多了 2 下拉加载 -1 更新中
      };
    },
    methods: {
      loadTop() {
        setTimeout(() => {  
          this.searchKey = {
            pageNum: 1,
            pageSize: this.pageSize - 0,
            state: this.state - 0,
            source: 1
          }
          this.taskList = []
          this.bottomAllLoaded = false
          this.getTasks()
          this.$refs.loadmore.onTopLoaded()
        }, 1000);
      },
      handleBottomChange(status) {
        this.listState = -1;
        this.bottomStatus = status;
      },
      loadBottom() { 
        setTimeout(() => {  
          this.searchKey.pageNum++;  
          this.getTasks();  
          this.$refs.loadmore.onBottomLoaded()
        }, 1000);
      },
      getTasks() {
        getTaskList(this.searchKey)
          .then(res => {
            let list = res.data.list.map(o => {
              o.updateDate = formatDate(
                new Date(o.updateDate),
                "yyyy-MM-dd HH:mm:ss"
              )
              return o
            })
            const { pageSize, pageNum } = this.searchKey;
            this.taskList = this.taskList.concat(list)
            if (list.length < pageSize) {
              this.bottomAllLoaded = true;
            }
            const { total } = res.data;
            if (total === 0) {
              this.listState = 0;
            } else if (total <= Number(pageSize) * Number(pageNum)) {
              this.listState = 1;
            } else {
              this.listState = 2;
            }
          })
          .catch(err => {
          });
      }
    },
    created() {},
    mounted() {
      this.getTasks()
    },
  
  };
</script>

<style lang='less' scoped>
  .page-loadmore-wrapper {
    // overflow: scroll;
    height: 100%;
  }
  .mint-loadmore{
    overflow:visible
  }
  ul.task-list {
    padding: 0 20px 10px;
  }
  
  .mint-spinner-fading-circle {
    margin: auto
  }

  .load-more {
    text-align: center;
  }
</style>