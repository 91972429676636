/*
 * @Author: SongYijie
 * @Date: 2020-05-06 17:54:45
 * @LastEditors: SongYijie
 */ 
import service from '@utils/request'

// 获取任务列表
const getTaskList = (data) => {
  return service({
    url: '/front/userTaskController/userTaskList',
    method: 'post',
    data: data
  })
}

// 获取任务详情
const getTaskDetail = (data) => {
  return service({
    url: '/front/userTaskController/taskDetails',
    method: 'post',
    data: data
  })
}

// 领取任务/确认签约
const receiveTask = (data) => {
  return service({
    url: '/front/userTaskController/taskReceive',
    method: 'post',
    data: data
  })
}
// 查询合同模板
// POST /front/userTaskController/getContractTemplate
const getUserSigningTpl = (data) => {
  return service({
    url: '/front/userTaskController/getContractTemplate/',
    method: 'post',
    data
  })
}

// 用户已签约的公司列表
const getUserSigningList = (taskId) => {
  return service({
    url: '/front/userTaskController/getUserSigning/' + taskId,
    method: 'get'
  })
}
// 查询合同详情
// GET /front/userTaskController/getContractTemplate/{userContractDetailId} 查询已签约协议
const getContractDetail = (userContractDetailId) => {
  return service({
    url: '/front/userTaskController/getContractTemplate/' + userContractDetailId,
    method: 'get'
  })
}

export {
  getTaskList,
  getTaskDetail,
  receiveTask,
  getUserSigningTpl,
  getUserSigningList,
  getContractDetail
}
