<!--  -->
<template>
  <li class="clearfix">
    <!-- <router-link :to="{name:'taskDetail', params:{id:task.id}}"> -->
    <router-link :to="`/task/detail/${task.id}`">
      <div class="pannel-header">
        <h3>
          <i class="bg-red icon"></i>
          {{task.companyName}}
        </h3>
        <span v-if="task.userTaskState === 0" class="status">未领取</span>
        <span v-else-if="task.userTaskState === 1" class="status nosigning">未签约</span>
        <span v-else-if="task.userTaskState === 2" class="status signing">签约中</span>
        <span v-else-if="task.userTaskState === 3" class="status signinged">签约成功</span>
      </div>
      <div class="pannel-body clearfix">
        <div class="col col-left">
          <div class="txt-black title">{{task.taskName}}</div>
          <div class="intro">{{task.itemsList}}</div>
          <div class="txt-gray-lighter time">{{task.updateDate}}</div>
        </div>
        <div class="col col-right">
          <div class="title txt-red">{{task.priceDesc}}</div>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ["task"],
};
</script>

<style lang='less' scoped>
a {
  text-decoration: none;
}
li {
  margin: 20px 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.1);
  .pannel-header {
    border-bottom: 1px solid #e6e6e6;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      display: block;
      line-height: 1rem;
      font-weight: 100;
      .icon {
        display: inline-block;
        width: 3px;
        height: 17px;
        margin-right: 8px;
        float: left;
      }
      font-size: 1rem;
      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .status {
      white-space: nowrap;
      display: inline-block;
      padding: 0 6px;
      height: 21px;
      border: 1px solid rgba(201, 46, 38, 1);
      font-size: 13px;
      color: rgba(201, 46, 38, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      flex-shrink: 0;
    }

    .nosigning {
      color: #de7d00;
      border-color: #de7d00;
    }

    .signing {
      color: #40a9ff;
      border-color: #40a9ff;
    }

    .signinged {
      color: #73d13d;
      border-color: #73d13d;
    }
  }
  .pannel-body {
    padding: 12px;
    font-size: 1rem;
    min-height: 6.5rem;
    .col {
      float: left;
      &.col-left {
        width: 67%;
      }
      &.col-right {
        width: 33%;
        text-align: right;
        .min {
          font-size: 0.8rem;
        }
      }
    }
    .title {
      word-break: break-all;
      font-weight: bold;
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin-bottom: 15px;
    }
    .intro {
      color: #666;
      font-size: 1rem;
      line-height: 1.8rem;
      min-width: 3.5rem;
      padding: 15px 0;
    }
    .time {
      margin-top: 15px;
      font-size: 0.8rem;
    }
  }
}
</style>
